import React, { useRef } from "react"
import Layout from "../components/Layout"
import { Flex } from "rebass"

export default function Test() {
  const inputEl = useRef(null)
  const playVideo = () => {
    inputEl.current.play()
  }
  const stopVideo = () => {
    inputEl.current.pause()
  }
  return (
    <Layout>
      <Flex>
        <div
          onMouseOver={playVideo}
          onMouseLeave={stopVideo}
          style={{
            border: "solid",
            padding: "10px",
            width: "400px",
            height: "400px",
          }}
        >
          <video
            ref={inputEl}
            playsInline
            loop
            muted
            style={{ width: "100%", objectFit: "cover" }}
          >
            <source
              src={
                "http://videos.ctfassets.net/jyrf2aeqxxi9/5rJjsdAbRvGzz2wYSty84W/9b1d2fd670520c7f1274bafb854c3c7e/In_the_game.mp4"
              }
              type="video/mp4"
            />
            <p>
              Video could not be found.{" "}
              <a href="mailto:greg@plank.ai">Please let me know</a>
            </p>
          </video>
        </div>
      </Flex>
    </Layout>
  )
}
